import React, { useContext } from "react";
import "./CartItems.css";
import cross_icon from "../Assets/cart_cross_icon.png";
import { ShopContext } from "../../Context/ShopContext";
import { backend_url, currency } from "../../App";

const CartItems = () => {
  const { products } = useContext(ShopContext);
  const { cartItems, removeFromCart, getTotalCartAmount } =
    useContext(ShopContext);
  const product = products.find((e) => e.id === 1);
  console.log(product);

  async function CheckOutFunction(e) {
    console.log("Checking Out");
    if (localStorage.getItem("auth-token")) {
      console.log("User is logged in");
      let productData = products.map((e) => {
        if (cartItems[e.id] > 0) {
          return {
            id: e.id,
            quantity: cartItems[e.id],
            name: e.name,
            price: e.new_price,
            image: e.image.at(0),
          };
        }
        return null;
      });
      productData = productData.filter((e) => e !== null);

      let data = {
        products: productData,
        total: getTotalCartAmount(),
      };
      fetch(`${backend_url}/checkout`, {
        method: "POST",
        headers: {
          Accept: "application/form-data",
          "auth-token": `${localStorage.getItem("auth-token")}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((resp) => resp.json())
        .then((data) => {
          console.log(data);
          if (data.status === "success") {
          } else {
            e.preventDefault();
            alert("Checkout Failed");
          }
        });
    } else {
      console.log("User is not logged in");
    }
  }

  return (
    <div className="cartitems">
      <div className="cartitems-format-main">
        <p>Products</p>
        <p>Title</p>
        <p>Price</p>
        <p>Quantity</p>
        <p>Total</p>
        <p>Remove</p>
      </div>
      <hr />
      {products.map((e) => {
        if (cartItems[e.id] > 0) {
          return (
            <div>
              <div className="cartitems-format-main cartitems-format">
                <img
                  className="cartitems-product-icon"
                  src={e.image.at(0)}
                  alt=""
                />
                <p cartitems-product-title>{e.name}</p>
                <p>
                  {currency}
                  {e.new_price}
                </p>
                <button className="cartitems-quantity">
                  {cartItems[e.id]}
                </button>
                <p>
                  {currency}
                  {e.new_price * cartItems[e.id]}
                </p>
                <img
                  onClick={() => {
                    removeFromCart(e.id);
                  }}
                  className="cartitems-remove-icon"
                  src={cross_icon}
                  alt=""
                />
              </div>
              <hr />
            </div>
          );
        }
        return null;
      })}

      <div className="cartitems-down">
        <div className="cartitems-total">
          <h1>Cart Totals</h1>
          <div>
            <div className="cartitems-total-item">
              <p>Subtotal</p>
              <p>
                {currency}
                {getTotalCartAmount()}
              </p>
            </div>
            <hr />
            <div className="cartitems-total-item">
              <p>Shipping Fee</p>
              <p>Free</p>
            </div>
            <hr />
            <div className="cartitems-total-item">
              <h3>Total</h3>
              <h3>
                {currency}
                {getTotalCartAmount()}
              </h3>
            </div>
          </div>

          <a
            href="https://wa.me/message/W7CJYUBLS6B5L1"
            target="_blank"
            rel="noreferrer"
            onClick={CheckOutFunction}
          >
            <button>PROCEED TO CHECKOUT</button>
          </a>
        </div>
        <div className="cartitems-promocode">
          <p>If you have a promo code, Enter it here</p>
          <div className="cartitems-promobox">
            <input type="text" placeholder="promo code" />
            <button>Submit</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartItems;
